//header
$headerHeight: 80px;

.header {
  display: flex;
  padding: 0 1rem;
  // background: black;
  justify-content: space-between;
  align-items: center;
  height: $headerHeight;
  background-image: linear-gradient(to right, #423d3d, #d5d5d5);
}
.logo {
  height: 80%;

  img {
    height: 100%;
  }
}
.marquee {
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 20px;
}
.launching {
  font-size: 1.6rem;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  animation: color-change 3s infinite;
  margin-right: 1rem;
}
.description {
  span {
    padding: 0 5px;
    font-size: 1.2em;
    font-weight: 600;
    animation: color-change2 2s infinite;
    display: inline-block;
  }
}

@keyframes color-change {
  0% {
    color: black;
    transform: scale(1);
  }
  50% {
    color: red;
    transform: scale(1.1);
  }
  100% {
    color: black;
    transform: scale(1);
  }
}

@keyframes color-change2 {
  0% {
    color: rgb(84, 251, 84);
    transform: scale(1);
  }
  50% {
    color: rgb(253, 253, 17);
    transform: scale(1.15);
  }
  100% {
    color: rgb(111, 245, 111);
    transform: scale(1);
  }
}

.home {
  height: calc(100vh - $headerHeight);
  overflow: auto;
}
//home
.block {
  display: flex;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  background: linear-gradient(to right, red, rgb(255, 246, 230));
  > div:first-child {
    height: 200px;
    width: 300px;
    border-radius: 10px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
.info {
  flex: 1;
  font-size: 1.2rem;
  margin-left: 1.5rem;
  // > div:first-child {
  //   font-size: 2rem;
  //   font-weight: 700;
  //   margin-bottom: 1rem;
  // }
}

.footer {
  border-top: 2px solid black;
  margin: 20px 0;
  padding: 20px 0;
  .description {
    font-size: 1.3rem;
  }
}

@media (max-width: 600px) {
  .block {
    > div:first-child {
      height: 120px;
      width: 160px;
    }
  }
}
